import { default as cookie } from 'js-cookie';
import ga from 'lib/ga';
import _, { get, isEmpty } from 'lodash';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { getUserProfile, searchArticles } from 'services';
import { verifyEmailCheckToken } from 'services/auth';
import { useDebounceValue } from 'usehooks-ts';
import { setAuthorization } from 'utils/axios.config';
import {
  DESTINATIONS_ALL_CITES_SLUG,
  DESTINATIONS_COUNTRIES,
  HALAL_FOOD_ALL_CITES_SLUG,
  HALAL_FOOD_COUNTRIES,
  LANGUAGES_SWITCHER,
} from '../constants';
import { clearAllDataStorage } from '../helpers';
import Loading from './loading';
import RegisterModal from './registration/v2/RegisterV2';
import SearchArticles from './searchArticles';
import { ToastError, ToastSuccess } from './toastify';

const Header = ({ isBotCrawler, allowLocales = [] }) => {
  const searchInputRef = useRef(null);
  const userId = cookie.get('user_id');
  const router = useRouter();
  const { locale = 'en' } = router;

  const [articleSearching, setArticleSearching] = useState(null);
  const [valueSearch, setValueSearch] = useDebounceValue(null, 400);
  const [cook, setCook] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedLang, setSelectedLang] = useState(locale);
  const [langOptions, setLangOptions] = useState(LANGUAGES_SWITCHER);

  const handleChange = (e) => {
    const value = e.target.value;
    setValueSearch(value?.trim());
  };

  const handleRequestSearching = async () => {
    gtag('event', 'search', {
      event_category: 'Search',
      event_label: valueSearch,
      event_value: 1,
      'dimension{metric1}': '1',
    });

    gtag('set', {
      metric1: '1',
    });

    ga.matomoEvent({
      category: 'Search',
      action: 'Search article',
      name: valueSearch,
    });

    if (valueSearch && valueSearch.length > 3) {
      const model = {
        limit: 5,
        page: 1,
        search: valueSearch,
        locale,
      };

      setLoading(true);
      searchArticles(model)
        .then((res) => {
          if (get(res, 'data.status') == 1)
            setArticleSearching(get(res, 'data'));
          else ToastError(get(res, 'data.message'));

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(async () => {
    const isTokenValid = router?.query?.token_valid;
    const uidb64 = router?.query?.uidb64;
    const token = router?.query?.token;
    const message = router?.query?.message;
    if (
      isTokenValid === 'True' &&
      uidb64 &&
      token &&
      message === 'Email_Login'
    ) {
      try {
        const result = await verifyEmailCheckToken({
          token: token,
          uidb64: uidb64,
        });
        const tokenAccess = get(result, 'data.data.tokens.access');
        localStorage.setItem('user_token', tokenAccess);
        setAuthorization(tokenAccess);

        handleGetUserProfile(get(result, 'data.data'));
      } catch (error) {
        console.error(error);
      }
    }
  }, [router.isReady, router.query]);

  useEffect(() => {
    if (valueSearch !== null) handleRequestSearching();
  }, [valueSearch]);

  const handleGetUserProfile = (dataLogin) => {
    if (get(dataLogin, 'user_id')) {
      getUserProfile(get(dataLogin, 'user_id'))
        .then((res) => {
          const { data } = res.data;
          if (get(res, 'data.status') == 1) {
            const fullname = get(data, 'last_name')
              ? get(data, 'first_name') + ' ' + get(data, 'last_name')
              : get(data, 'first_name');

            localStorage.setItem('logged_user_id', get(data, 'id'));
            localStorage.setItem('logged_user_name', fullname);
            localStorage.setItem('logged_user_email', get(data, 'email'));
            localStorage.setItem(
              'logged_user_lastname',
              get(data, 'last_name')
            );
            localStorage.setItem(
              'logged_user_firstname',
              get(data, 'first_name')
            );
            localStorage.setItem('logged_user_photo', get(data, 'avatar'));
            localStorage.setItem('username', fullname);

            //cookies
            cookie.set('logged_user_name', fullname);
            cookie.set('logged_user_email', get(data, 'email'));
            cookie.set('logged_user_lastname', get(data, 'last_name'));
            cookie.set('logged_user_firstname', get(data, 'first_name'));
            cookie.set('logged_user_photo', get(data, 'avatar'));
            cookie.set('username', fullname);
            cookie.set('user_id', get(data, 'id'), {
              expires: 2 / 24,
            });

            ToastSuccess('Login successfully.');
            // setTimeout(() => (window.location.href = '/'), 400);
          } else {
            const { message } = res.data;
            ToastError({ message });
          }
        })
        .catch((error) => {
          const { data } = error.response;
          ToastError(messages[data.message]);
        });
    }
  };

  useEffect(() => {
    if (!userId) {
      setCook(false);
      clearAllDataStorage();
    } else {
      setCook(true);
    }
  }, [userId]);

  // useEffect(() => {
  //   $('.val_save_1').hide();
  //   $('.val_save_2').hide();
  //   $('.val_save_3').hide();
  //   $('.val_save_4').hide();
  //   $('.val_save_5').hide();
  // }, []);

  useEffect(() => {
    const isTokenValid = router?.query?.token_valid;
    const uidb64 = router?.query?.uidb64;
    const token = router?.query?.token;
    const message = router?.query?.message;

    if (
      isTokenValid === 'True' &&
      uidb64 &&
      token &&
      message === 'Credentials Valid'
    ) {
      $('.step-1').hide();
      $('.step-8').show();
      $('#login-signup').modal('show');
    }
  }, []);

  useEffect(() => {
    if (router.query?._l == 'true') {
      clearAllDataStorage();

      setTimeout(() => {
        window.location = '/';
      }, 1500);
    }

    if (!isEmpty(router.query)) {
      $('.nav-encl .navbar-collapse').removeClass('menu-active');
      $('.menu-overlay').hide();
    }
    $('body').removeClass('overflow-hidden');
  }, [router.query]);

  useEffect(() => {
    setSelectedLang(locale);
  }, [locale]);

  useEffect(() => {
    if (allowLocales?.length > 0) {
      const filteredLangs = [];
      const langs = [...langOptions];

      langs.map((lang) => {
        if (allowLocales.includes(lang.value)) filteredLangs.push(lang);
        else return;
      });

      setLangOptions(filteredLangs);
    }
  }, [allowLocales]);

  const viewProfile = (e) => {
    e.preventDefault();
    window.location.href = '/profile';
  };

  const handleSearchOpenClick = () => {
    $('.float-search').slideDown('easeIn');
    searchInputRef?.current?.focus();
  };

  const handleSearchCloseClick = () => {
    $('.float-search').slideUp('easeOut');
  };

  const handleOpenLoginModal = () => {
    $('.step-1').show();
    $('.step-2').hide();
    $('.step-3').hide();
    $('.step-4').hide();
    $('.step-5').hide();
    $('.step-6').hide();
    $('.step-7').hide();
    $('.step-8').hide();
    $('.login-s-1').hide();
    $('#login-signup').modal('show');
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    if (valueSearch && valueSearch.trim()) {
      router.push(`/search/${valueSearch}`, undefined, { locale });
    }
  };

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Have Halal Will Travel",
              "url": "${process.env.NEXT_PUBLIC_LANDING_URL}${
              locale === 'en' ? '' : `/${locale}`
            }",
              "description": "Your ultimate guide to halal travel and lifestyle",
              "author": {
                "@type": "Organization",
                "name": "Have Halal Will Travel"
              },
              "publisher": {
                "@type": "Organization",
                "name": "Have Halal Will Travel",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://minio.havehalalwilltravel.com/hhwt-upload/old-images/hhwt_logo.png",
                  "width": 600,
                  "height": 200
                }
              }
            }
          `,
          }}
        ></script>
      </Head>

      {isLoading && <Loading />}

      <header id="pageHeader" className="container-fluid">
        <div className="header-row">
          <a
            href={locale === 'en' ? '/' : `/${locale}`}
            className="navbar-brand"
          >
            <img
              src="/images/logo-white.svg"
              alt="hhwt-logo"
              title="logo-image"
            />
          </a>
          <form className="search-hdr d-none d-lg-block">
            <div className="input-group search-lg-btn">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <img
                    width={24}
                    height={25}
                    src="/images/search-icon.svg"
                    alt="icon"
                  />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search HHWT..."
                onClick={handleSearchOpenClick}
              />
            </div>
          </form>

          <div className="nav-encl">
            <nav className="navbar navbar-expand-md p-0">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => {
                  $('body').toggleClass('overflow-hidden');
                  window
                    .jQuery('.nav-encl .navbar-collapse')
                    .toggleClass('menu-active');
                  $('.menu-overlay').fadeToggle(200);
                }}
              >
                <span className="navbar-toggler-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              {/* navbar on mobile */}
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  {/* HALAL FOOD */}
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="halal-food-menu"
                      role="button"
                      data-toggle="dropdown"
                      aria-roledescription="navigation toggle"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        width={24}
                        height={24}
                        className="menu-ico"
                        src="/images/menu-ico-food.svg"
                        alt="halal-food-icon"
                      />
                      <span className="menu-text">Halal Food</span>
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="halal-food-menu"
                    >
                      {_.map(HALAL_FOOD_COUNTRIES, (item) => (
                        <li
                          key={item.id}
                          className={`${
                            item.slug?.includes(router.query?.halal)
                              ? 'item-active'
                              : ''
                          }`}
                        >
                          <Link href={item.slug}>
                            <a className="dropdown-item">{item.name}</a>
                          </Link>
                        </li>
                      ))}
                      <li className="dropdown-divider"></li>
                      <li>
                        <Link href={HALAL_FOOD_ALL_CITES_SLUG}>
                          <a className="dropdown-item">
                            View all cities&nbsp;
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* DESTINATIONS */}
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      id="destination-menu"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        width={24}
                        height={24}
                        className="menu-ico"
                        src="/images/menu-ico-explore.svg"
                        alt="icon"
                      />
                      <span className="menu-text">Destinations</span>
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="destination-menu"
                    >
                      {_.map(DESTINATIONS_COUNTRIES, (item) => (
                        <li
                          key={item.id}
                          className={`${
                            item.slug?.includes(router.query?.travel)
                              ? 'item-active'
                              : ''
                          }`}
                        >
                          <Link href={item.slug}>
                            <a className="dropdown-item">{item.name}</a>
                          </Link>
                        </li>
                      ))}
                      <li className="dropdown-divider"></li>
                      <li>
                        <Link href={DESTINATIONS_ALL_CITES_SLUG}>
                          <a className="dropdown-item">
                            View all cities&nbsp;
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* ITINERARY */}
                  <li className="nav-item">
                    <Link href="/category/itinerary">
                      <a className="nav-link">
                        <img
                          width={24}
                          height={24}
                          className="menu-ico"
                          src="/images/menu-ico-itinerary.svg"
                          alt="icon"
                        />
                        <span className="menu-text">Itinerary</span>
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <div className="profile-block">
            <div
              className="search-lg-btn individual d-lg-none"
              onClick={handleSearchOpenClick}
            >
              <img
                width={24}
                height={24}
                className="white"
                src="/images/search-icon.svg"
                alt="icon"
              />
            </div>

            {/* LANGUAGES */}
            {/* <div className="language-switcher">
              <Select
                options={langOptions}
                value={selectedLang}
                getOptionLabel={(opt) => (
                  <div className="language-option">
                    <div className="option-flag">
                      <img src={opt.icon?.src} alt="flag-icon" />
                    </div>
                    <span className="option-label">{opt.label}</span>
                  </div>
                )}
                onChange={(opt) =>
                  (window.location.href = `${
                    process.env.NEXT_PUBLIC_LANDING_URL
                  }/${opt.value === 'en' ? '' : opt.value}/${router.asPath}`)
                }
              />
            </div> */}

            {/* {cook ? (
              <div
                onClick={() => router.push('/profile')}
                className="btn-login profile-dd"
              >
                {!isEmpty(cookie.get('logged_user_photo')) ? (
                  <img
                    className="prof-img"
                    src={cookie.get('logged_user_photo')}
                    alt="icon"
                    width={40}
                    height={40}
                  />
                ) : (
                  <img
                    className="prof-img"
                    src="/images/img-hhwt-logo.png"
                    alt="prof-img"
                    width={40}
                    height={40}
                  />
                )}
              </div>
            ) : (
              <button className="btn btn-login" onClick={handleOpenLoginModal}>
                Login
              </button>
            )} */}
          </div>
        </div>
      </header>

      <div className="menu-overlay"></div>

      {/* search */}
      <div className="float-search animated faster">
        <div className="container">
          <form className="search-hdr" onSubmit={handleSubmitSearch}>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <img src="/images/search-icon.svg" alt="icon" />
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search HHWT..."
                ref={searchInputRef}
                onChange={handleChange}
              />
            </div>
            <span className="input-close">
              <img
                className="img-fluid"
                onClick={handleSearchCloseClick}
                src="/images/close-icon.png"
                alt="icon"
              />
            </span>
          </form>
        </div>

        <SearchArticles article={articleSearching} valueSearch={valueSearch} />
      </div>

      {/* Register Modal */}
      {!isBotCrawler && <RegisterModal />}
    </>
  );
};

export default Header;

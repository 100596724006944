import { API_ROUTES } from 'utils/apiRoutes';
import { api } from 'utils/axios.config';

export const loginWithEmailToken = (model) => {
  const url = API_ROUTES.login_with_email_otp;
  return api.post(url, model);
};

export const loginWithPhoneOTP = (model) => {
  const url = API_ROUTES.login_with_phone_otp;
  return api.post(url, model);
};

export const verifyEmailCheckToken = (model) => {
  const url = API_ROUTES.verify_email_otp + `${model.uidb64}/${model.token}/`;
  return api.get(url);
};

export const getListCountry = () => {
  const url = API_ROUTES.list_country;
  return api.get(url);
};

export const getListCity = (model) => {
  const url = API_ROUTES.list_city + `?country_name=${model.country_name}`;
  return api.get(url);
};

export const getInfoUser = (model) => {
  const url = API_ROUTES.get_user + `${model.uidb64}/${model.token}/`;
  return api.get(url, model);
};

export const updateUser = (model) => {
  const url = API_ROUTES.update_user;
  return api.post(url, model);
};

export const addPersonalization = (model) => {
  const url = API_ROUTES.personalization_user;
  return api.post(url, model);
};

export const getProfileFavouritesUpvoted = (model) => {
  const url = API_ROUTES.get_profile_favourites_upvoted;
  return api.get(url, { params: model });
};

export const getProfileReview = (model) => {
  const url = API_ROUTES.get_profile_review;
  return api.get(url, { params: model });
};

export const getProfileUser = (user_id) => {
  const url = API_ROUTES.get_profile_user + `?user_id=${user_id}`;
  return api.get(url);
};

export const verifyPhoneCheckOTPLogin = (model) => {
  const url = API_ROUTES.verify_phone_otp_login;
  return api.post(url, model);
};

export const getProfileFavouritesBlogPage = (model) => {
  const url = API_ROUTES.get_profile_favourites_blogpage;
  return api.get(url, { params: model });
};

export const getProfileFavouritesVenue = (model) => {
  const url = API_ROUTES.get_profile_favourites_venue;
  return api.get(url, { params: model });
};